<template>
  <div>
    <v-text-field
      label="Default translation"
      v-model="defaultTranslation"
    />
    <v-checkbox
      v-model="showTranslations"
      label="Show translation component"
    />
    Active translations
    <v-checkbox
      v-for="translation in systemTranslations"
      v-model="usedTranslations"
      dense
      :value="translation"
      :key="translation"
      :label="translation"
    />
    <translations
      v-if="showTranslations"
      :translations.sync="translations"
    />
    <div>{{translations}}</div>

  </div>
</template>

<script>
export default {
  components: {
    Translations: () => import('@/components/translations')
  },
  data () {
    return {
      defaultTranslation: '',
      showTranslations: false,
      systemTranslations: [],
      usedTranslations: [],
      translations: {}
    }
  },
  async created () {
    const e = await this.$http().get('/core/v6/settings/cultures/ui')
    this.systemTranslations = e.data.cultures
    this.usedTranslations = e.data.cultures
  },
  watch: {
    usedTranslations (translations) {
      [...Object.keys(this.translations)]
        .forEach(t => {
          if (!translations.includes(t)) {
            this.$delete(this.translations, t)
          }
        })
      translations.forEach(t => this.$set(this.translations, t, this.translations[t] ?? this.defaultTranslation))
    },
    defaultTranslation (v) {
      [...Object.keys(this.translations)]
        .forEach(t => {
          this.$set(this.translations, t, v)
        })
    }
  }
}
</script>
